import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { FaMapMarkerAlt, FaCalendar } from 'react-icons/fa'

const NewMeeting = () => (
  <StaticQuery
    query={graphql`
      query NewMeetingQuery {
        allContentfulMeetings(limit: 1, sort: {fields: date, order: DESC}) {
          edges {
            node {
              date(formatString: "LLLL")
              title
              address
              location {
                lat
                lon
              }
              url
              agenda {
                agenda
              }
            }
          }
        }
      }
    `}
    render={data => {
      let agenda = data.allContentfulMeetings.edges[0].node.agenda.agenda.split('\n').map((item, i) => {
        return <span key={i}>{item}<br /></span>;
      })
      let lat = data.allContentfulMeetings.edges[0].node.location.lat
      let lon = data.allContentfulMeetings.edges[0].node.location.lon
      return (
        <>
          <div className="card">
            <div className="card-header">Latest Meeting</div>
            <div className="card-body">
              <h5 className="card-title">{data.allContentfulMeetings.edges[0].node.title}</h5>
              <p className="card-text">
                <FaCalendar style={{ verticalAlign: 'baseline', marginRight: '10px'}}/>{data.allContentfulMeetings.edges[0].node.date}<br />
                <FaMapMarkerAlt style={{ verticalAlign: 'baseline', marginRight: '10px' }}/>
                <a href={'https://www.google.com/maps/search/?api=1&query=' + lat + ',' + lon} target="_blank" rel="noopener noreferrer">
                {data.allContentfulMeetings.edges[0].node.address}
                </a>
              </p>
              <h5 className="card-title">Agenda</h5>
              <p className="card-text">{agenda}</p>
            </div>
          </div>
          <br />
        </>
      )
    }}
  />
)

export default NewMeeting