import React from "react"
import { Link, StaticQuery, graphql } from "gatsby"
import { BLOCKS, MARKS } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Seo from "../components/seo"
import NewMeeting from "../components/newMeeting"

const SecondPage = () => (
  <StaticQuery 
    query={graphql`
      query EventsPageQuery {
        allContentfulMeetings(sort: {fields: date, order: DESC}, filter: {visible: {eq: true}}) {
          edges {
            node {
              date(formatString: "LLLL")
              title
              address
              location {
                lat
                lon
              }
              url
              agenda {
                agenda
              }
            }
          }
        }
        contentfulPage(pageName: {eq: "Meetings"}) {
          id
          pageContent {
            raw
            references {
              ... on ContentfulAsset {
                __typename
                contentful_id
                title
                gatsbyImageData
              }
            }
          }
        }
      }
    `}
    render = {data => {
      const Bold = ({ children }) => <span className="bold">{children}</span>
      const Text = ({ children }) => <p className="align-center">{children}</p>
      
      const options = {
        renderMark: {
          [MARKS.BOLD]: text => <Bold>{text}</Bold>,
        },
        renderNode: {
          [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
          [BLOCKS.EMBEDDED_ASSET]: node => {
            const image = getImage(node.data.target)
            return (
              <>
                <GatsbyImage image={image} alt={node.data.target.title} style={{display: 'block', margin: '0 auto'}} />
              </>
            )
          },
        },
      }


      return (
        <>
          <Seo title="Events & Meetings" />
          {renderRichText(data.contentfulPage.pageContent, options)}
          <NewMeeting />
          <Link to="/">Go back to the homepage</Link>
        </>
      )
    }
    }
  />
)

export default SecondPage
